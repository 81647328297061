import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faItchIo } from '@fortawesome/free-brands-svg-icons';
import './project.css';
import coverImage from './CowboyCasino/cover.png';
import cc_blackjack from './CowboyCasino/blackjack.png';
import cc_poker from './CowboyCasino/poker.png';
import cc_mainmenu from './CowboyCasino/home.png';
import cc_slots from './CowboyCasino/slots.png';
import cc_mainmenu_japanese from './CowboyCasino/home_japanese.png';
import cc_casino from './CowboyCasino/casino.png';

const data = {
  title: "Cowboy Casino",
  description: "Play blackjack, poker and slots against some cheatin' cowpokes. Available in English, Spanish, French, German, Italian, Portuguese, Russian, Chinese, Japanese, and Korean.",
  images: [
    { src: cc_mainmenu, title: "Home Screen" },
    { src: cc_mainmenu_japanese, title: "Home Screen Japanese" },
    { src: cc_poker, title: "Poker" },
    { src: cc_blackjack, title: "Blackjack" },
    { src: cc_slots, title: "Slots" },
    { src: cc_casino, title: "Casino" }
  ],
  link: "https://capsizegames.itch.io/cowboy-casino",
  releaseDate: "December 12, 2024",
  platforms: ["PC"],
  tools: ["Unity3D", "C#", "LMMS", "Aesprite"],
  status: "Released",
  publisher: "Capsize Games",
  embedCode: "<iframe frameborder=\"0\" src=\"https://itch.io/embed-upload/12310981?color=111111\" allowfullscreen=\"\" width=\"980\" height=\"640\" style=\"margin-bottom: 20px\"><a href=\"https://capsizegames.itch.io/cowboy-casino\">Play Cowboy Casino on itch.io</a></iframe>"
};

class CowboyCasino extends React.Component {
  render() {
    const isMobile = window.innerWidth <= 768;

    return (
      <div className="section-content">
        <Helmet>
          <title>Cowboy Casino - Capsize Games</title>
          <meta name="description" content="Play blackjack, poker and slots against some cheatin' cowpokes." />
        </Helmet>
        <div className="project">
          {!isMobile && data.embedCode ? <div dangerouslySetInnerHTML={{ __html: data.embedCode }} /> : null}
          <div className="project-header">
            {!isMobile && <img src={coverImage} alt="product cover" className="coverimage" />}
            <div>
                <h3>{data.title}</h3>
                <p className="description">{data.description}</p>
                <table className="details">
                    <tbody>
                        <tr>
                        <td><strong>Status</strong></td>
                        <td>{data.status}</td>
                        </tr>
                        {data.releaseDate ? (
                        <tr>
                            <td><strong>Release date</strong></td>
                            <td>{data.releaseDate}</td>
                        </tr>
                        ) : null}
                        <tr>
                        <td><strong>Platforms</strong></td>
                        <td>{data.platforms.join(', ')}</td>
                        </tr>
                        <tr>
                        <td><strong>Tools</strong></td>
                        <td>{data.tools.join(', ')}</td>
                        </tr>
                        <tr>
                        <td><strong>Marketplaces</strong></td>
                        <td>{data.link ? (<a href={data.link}><FontAwesomeIcon icon={faItchIo} /> Itch.io</a>) : null}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
          <div className="images">
            {data.images.map((image, index) => (
              <div key={`image-key-${index}`} className="image-container" onClick={() => this.showImage(image.src)}>
                <img alt="project" style={{ width: 256, height: 144 }} src={image.src} />
                <div className="image-text-container">
                  <div className="image-text">{image.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default CowboyCasino;