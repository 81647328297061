import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faItchIo } from '@fortawesome/free-brands-svg-icons';
import './project.css';
import coverImage from './SuperStackSolitaire/cover.png';
import homeScreenImage from './SuperStackSolitaire/home.png';
import pryamidScreenImage from './SuperStackSolitaire/pyramid.png';
import klondikeImage from './SuperStackSolitaire/klondike.png';

const data = {
  title: "Super Stack Solitaire",
  description: "Pyramid and Klondike solitaire featuring casual and arcade modes with an 8-bit aesthetic following a modified NES spec.",
  images: [
    { src: homeScreenImage, title: "Home Screen" },
    { src: pryamidScreenImage, title: "Pyramid" },
    { src: klondikeImage, title: "Klondike" }
  ],
  link: "https://capsizegames.itch.io/super-stack-solitaire",
  releaseDate: "June 05, 2022",
  platforms: ["PC"],
  tools: ["Unity3D", "C#", "FamiTracker", "Aesprite"],
  status: "Released",
  publisher: "Capsize Games",
  embedCode: "<iframe frameborder=\"0\" src=\"https://itch.io/embed-upload/7709449?color=266400\" allowfullscreen=\"\" width=\"960\" height=\"560\" style=\"margin-bottom: 20px\"><a href=\"https://capsizegames.itch.io/super-stack-solitaire\">Play Super Stack Solitaire on itch.io</a></iframe>"
};

class SuperStackSolitaire extends React.Component {
  render() {
    const isMobile = window.innerWidth <= 768;

    return (
      <div className="section-content">
        <Helmet>
          <title>Super Stack Solitaire - Capsize Games</title>
          <meta name="description" content="Pyramid and Klondike solitaire featuring casual and arcade modes with an 8-bit aesthetic following a modified NES spec." />
        </Helmet>
        <div className="project">
          {!isMobile && data.embedCode ? <div dangerouslySetInnerHTML={{ __html: data.embedCode }} /> : null}
          <div className="project-header">
            {!isMobile && <img src={coverImage} alt="product cover" className="coverimage" />}
            <div>
                <h3>{data.title}</h3>
                <p className="description">{data.description}</p>
                <table className="details">
                    <tbody>
                        <tr>
                        <td><strong>Status</strong></td>
                        <td>{data.status}</td>
                        </tr>
                        {data.releaseDate ? (
                        <tr>
                            <td><strong>Release date</strong></td>
                            <td>{data.releaseDate}</td>
                        </tr>
                        ) : null}
                        <tr>
                        <td><strong>Platforms</strong></td>
                        <td>{data.platforms.join(', ')}</td>
                        </tr>
                        <tr>
                        <td><strong>Tools</strong></td>
                        <td>{data.tools.join(', ')}</td>
                        </tr>
                        <tr>
                        <td><strong>Marketplaces</strong></td>
                        <td>{data.link ? (<a href={data.link}><FontAwesomeIcon icon={faItchIo} /> Itch.io</a>) : null}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
          <div className="images">
            {data.images.map((image, index) => (
              <div key={`image-key-${index}`} className="image-container">
                <img alt="project" style={{ width: 256, height: 144 }} src={image.src} />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default SuperStackSolitaire;