import React from 'react';
import moment from 'moment';
import {
  HashRouter,
  Routes,
  Route,
  Outlet,
  useLocation,
  useNavigate
} from "react-router-dom";
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBluesky, faDiscord, faYoutube, faInstagram, faItchIo, faArtstation } from '@fortawesome/free-brands-svg-icons';
import { faHamburger } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import Projects from './Projects';
import Contact from './Contact';
import About from './About';
import CowboyCasino from './CowboyCasino';
import AIRunner from './AIRunner';
import SuperStackSolitaire from './SuperStackSolitaire';
import logo from './capsizelogo.png';
import './reset.css';
import './App.css';

const navigation = ["projects", "about", "contact"];

class Footer extends React.Component {
  render() {
    return (
      <p className="footer">
        &copy; {moment().format('YYYY')} Capsize LLC <a href="https://joecurlee.com" title="Joe Curlee's personal website">all rights reserved</a>
      </p>
    );
  }
}

const Home = () => {
  const navigate = useNavigate();

  const renderNavigation = () => (
    <div className="navigation">
      {navigation.map((nav, index) => (
        <span className="navbutton" onClick={() => navigate(`/${nav}`, { replace: true })} key={`navkey-${index}`}>{nav}</span>
      ))}
    </div>
  );

  return (
    <header className="App-header">
      <Helmet>
        <title>Home - Capsize Games</title>
        <meta name="description" content="Welcome to Capsize Games. Explore our projects and contact us for more information." />
        <meta name="keywords" content="Capsize Games, home, projects, contact" />
      </Helmet>
      <img alt="logo" className="home-logo" src={logo} />
      {renderNavigation()}
      <Footer />
    </header>
  );
}

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showHiddenLinks, toggleNav] = useState(false);

  const sociallinks = [
    {
      url: 'https://capsizegames.itch.io/',
      icon: faItchIo
    },
    {
      url: 'https://www.artstation.com/capsize',
      icon: faArtstation
    },
    {
      url: 'https://discord.gg/PUVDDCJ7gz',
      icon: faDiscord
    },
    {
      url: 'https://bsky.app/profile/capsizegames.com',
      icon: faBluesky
    },
    {
      url: 'https://www.youtube.com/channel/UCGp3N3DlNYk6BPR9-DXkSzA',
      icon: faYoutube
    },
    {
      url: 'https://www.instagram.com/capsizegames/',
      icon: faInstagram
    }
  ];

  const renderSocialLinks = () => sociallinks.map(link => (
    <li key={link.url}>
      <a target="_blank" href={link.url} rel="noreferrer">
        <FontAwesomeIcon icon={link.icon} />
      </a>
    </li>
  ));

  const renderNavigation = () => (
    <div className="top-navigation">
      <div className="navigation-container">
        <div className="brand">
          <span className="brandname" onClick={() => navigate('/', { replace: true })}>
            <img alt="logo" src={logo} />
          </span>
          <div className="navbar">
            {navigation.map((nav, index) => (
              <span className={`navbutton${location.pathname === `/${nav}` ? ' selected' : ''}`} onClick={() => navigate(`/${nav}`, { replace: true })} key={`navkey-${index}`}>{nav}</span>
            ))}
          </div>
        </div>
        <div className="socialLinks">
          <ul className="expandedNav">
            {renderSocialLinks()}
          </ul>
          <div className="socialLinks-collapsebutton">
            <button className="hamburgerButton" onClick={() => toggleNav(!showHiddenLinks)}>
              <FontAwesomeIcon icon={faHamburger} />
            </button>
            {showHiddenLinks ? (
              <div className="hiddenNav">
                <ul className="nav">
                  {navigation.map((nav, index) => (
                    <span className="navbutton" onClick={() => {
                      toggleNav();
                      navigate(`/${nav}`, { replace: true });
                    }} key={`navkey-${index}`}>{nav}</span>
                  ))}
                </ul>
                <ul className="social">
                  {renderSocialLinks()}
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="App">
      <div className="section">
        {location.pathname !== '/' ? renderNavigation() : null}
        <Outlet />
        <Footer />
      </div>
    </div>
  );
}

const AppMain = () => {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="projects" element={<Projects />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="projects/cowboy-casino" element={<CowboyCasino />} />
          <Route path="projects/ai-runner" element={<AIRunner />} />
          <Route path="projects/super-stack-solitaire" element={<SuperStackSolitaire />} />
        </Route>
      </Routes>
    </HashRouter>
  );
}

export default AppMain;