import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './projects.css';
import cc_cover from './CowboyCasino/cover.png';
import sss_cover from './SuperStackSolitaire/cover.png';
import air_cover from './AIRunner/cover.png';

class Projects extends React.Component {
  render() {
    return (
      <div className="section-content projects">
        <Helmet>
          <title>Projects - Capsize Games</title>
          <meta name="description" content="Explore the projects developed by Capsize Games, including Cowboy Casino, AI Runner, and Super Stack Solitaire." />
          <meta name="keywords" content="Capsize Games, projects, Cowboy Casino, AI Runner, Super Stack Solitaire" />
        </Helmet>
        <div className="item">
          <Link to="/projects/cowboy-casino">
            <img alt="product cover" src={cc_cover} />
            <div className="caption">Cowboy Casino</div>
          </Link>
        </div>
        <div className="item">
          <Link to="/projects/ai-runner">
            <img alt="product cover" src={air_cover} />
            <div className="caption">AI Runner</div>
          </Link>
        </div>
        <div className="item">
          <Link to="/projects/super-stack-solitaire">
            <img alt="product cover" src={sss_cover} />
            <div className="caption">Super Stack Solitaire</div>
          </Link>
        </div>
      </div>
    )
  }
}

export default Projects;