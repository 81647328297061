import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpaceShuttle } from '@fortawesome/free-solid-svg-icons'

class About extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <div className="section-content">
        <Helmet>
          <title>About Us - Capsize Games</title>
          <meta name="description" content="Learn more about Capsize Games, our team, and our mission." />
          <meta name="keywords" content="Capsize Games, about, team, mission" />
        </Helmet>
        <div className="about content">
          <h2><FontAwesomeIcon icon={faSpaceShuttle} /> About</h2>
          <p>
            Capsize Games is run by <a href="https://joecurlee.com" target="_blank" rel="noopener noreferrer" title="Joe Curlee's personal website">Joe Curlee</a>, a software engineer based in the United States.
            <br />
            He has a professional background in web development and has worked on a variety of projects, web applications, websites, distributed applications and games.
            <br />
            He is passionate about technology, AI, and game development. He is also a fan of comic books, retro gaming and science fiction.
          </p>
        </div>
      </div>
    )
  }
}

export default About;