import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'

class Contact extends React.Component {
  constructor() {
    super();
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>Contact Us - Capsize Games</title>
          <meta name="description" content="Contact Capsize Games for more information or inquiries." />
          <meta name="keywords" content="Capsize Games, contact, inquiries" />
        </Helmet>
        <div className="contact content">
          <h2><FontAwesomeIcon icon={faEnvelope} /> Contact</h2>
          Contact us at <a href="mailto:capsizegames@fastmail.com">capsizegames@fastmail.com</a>
        </div>
      </div>
    )
  }
}

export default Contact;