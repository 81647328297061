import React from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faItchIo } from '@fortawesome/free-brands-svg-icons';
import './project.css';
import coverImage from './AIRunner/cover.png';
import airunnerHomeScreen from './AIRunner/airunner_homescreen.png';
import airunnerHomeScreen2 from './AIRunner/airunner_2.png';
import airunnerHomeScreen3 from './AIRunner/airunner_3.png';

const data = {
  title: "AI Runner",
  description: "Stable Diffusion and Mistral-7b offline on your own hardware.",
  images: [
    { src: airunnerHomeScreen, title: "Home Screen" },
    { src: airunnerHomeScreen2, title: "Chatbot" },
    { src: airunnerHomeScreen3, title: "Art tools" }
  ],
  link: "https://capsizegames.itch.io/airunner",
  releaseDate: "2022",
  platforms: ["PC"],
  tools: ["Python", "Pyside6", "Huggingface"],
  status: "Released",
  publisher: "Capsize Games"
};

class AIRunner extends React.Component {
  render() {
    const isMobile = window.innerWidth <= 768;

    return (
      <div className="section-content">
        <Helmet>
          <title>AI Runner - Capsize Games</title>
          <meta name="description" content="Stable Diffusion and Mistral-7b offline on your own hardware." />
        </Helmet>
        <div className="project">
          <div className="project-header">
            {!isMobile && <img src={coverImage} alt="product cover" className="coverimage" />}
            <div>
                <h3>{data.title}</h3>
                <p className="description">{data.description}</p>
                <table className="details">
                    <tbody>
                        <tr>
                        <td><strong>Status</strong></td>
                        <td>{data.status}</td>
                        </tr>
                        {data.releaseDate ? (
                        <tr>
                            <td><strong>Release date</strong></td>
                            <td>{data.releaseDate}</td>
                        </tr>
                        ) : null}
                        <tr>
                        <td><strong>Platforms</strong></td>
                        <td>{data.platforms.join(', ')}</td>
                        </tr>
                        <tr>
                        <td><strong>Tools</strong></td>
                        <td>{data.tools.join(', ')}</td>
                        </tr>
                        <tr>
                        <td><strong>Marketplaces</strong></td>
                        <td>{data.link ? (<a href={data.link}><FontAwesomeIcon icon={faItchIo} /> Itch.io</a>) : null}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
          </div>
          <div className="images">
            {data.images.map((image, index) => (
              <div key={`image-key-${index}`} className="image-container" onClick={() => this.showImage(image.src)}>
                <img alt="project" style={{ width: 256, height: 144 }} src={image.src} />
                <div className="image-text-container">
                  <div className="image-text">{image.title}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default AIRunner;